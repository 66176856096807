var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        scopedSlots: _vm._u([
          {
            key: "header-title",
            fn: function () {
              return [_vm._v("Logs")]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  staticClass: "mb-5",
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.changeFilters,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap min-w-max" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "m-1" },
                                  [
                                    _c("sun-search-input", {
                                      attrs: {
                                        value:
                                          _vm.filterQuick["filtersApplied"],
                                        "class-input": "text-sm",
                                      },
                                      on: {
                                        search: function ($event) {
                                          return _vm.onQuickFiltersSearch(
                                            $event,
                                            "filtersApplied"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.offer.id`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("offer-filter", {
                              attrs: {
                                "client-id": _vm.activeClient.id,
                                service: filter.service,
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.createdAt`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-date-picker", {
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilterDate(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilterDate(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                            _c("sun-error-text", {
                              attrs: {
                                "show-error": !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.authorId`,
                        fn: function ({
                          filter,
                          onSelect,
                          isDuplicate,
                          value,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                name: "user-select-filter",
                                service: filter.service,
                                "track-by": filter.trackBy,
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                "class-input": "pt-0 shadow-none rounded-none",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    loading: _vm.isLoading,
                    hoverable: "",
                    striped: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.requestedAt`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.createdAt },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.requestedBy`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(" " + _vm._s(item.author?.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.dateFrom`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.startDate },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.dateTo`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.endDate },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.requestedReport`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              _vm._l(item.events, function (event, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(" " + _vm._s(event) + " "),
                                  index < item.events.length - 1
                                    ? _c("span", [_vm._v(" - ")])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.filtersApplied`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              item.filters.offers.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Offers:")]
                                      ),
                                      _vm._l(
                                        item.filters.offers,
                                        function (offer, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " + _vm._s(offer.name) + " "
                                            ),
                                            index <
                                            item.filters.offers.length - 1
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.filters.advertisers.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Advertisers: ")]
                                      ),
                                      _vm._l(
                                        item.filters.advertisers,
                                        function (advertiser, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(advertiser.name) +
                                                " "
                                            ),
                                            index <
                                            item.filters.advertisers.length - 1
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.filters.campaigns.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Campaigns: ")]
                                      ),
                                      _vm._l(
                                        item.filters.campaigns,
                                        function (campaign, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " + _vm._s(campaign.name) + " "
                                            ),
                                            index <
                                            item.filters.campaigns.length - 1
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.filters.publishers.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Publishers: ")]
                                      ),
                                      _vm._l(
                                        item.filters.publishers,
                                        function (publisher, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " + _vm._s(publisher.name) + " "
                                            ),
                                            index <
                                            item.filters.publishers.length - 1
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.filters.countries.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Countries: ")]
                                      ),
                                      _vm._l(
                                        item.filters.countries,
                                        function (country, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " + _vm._s(country.name) + " "
                                            ),
                                            index <
                                            item.filters.countries.length - 1
                                              ? _c("span", [_vm._v(", ")])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-4 py-2 hover:bg-gray-300 rounded",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDownloadLogReport(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("log-file-svg", {
                                    staticClass: "w-5 mr-1",
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  staticClass: "mb-8",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                    "options-per-page": _vm.availablePageSizes,
                  },
                  on: {
                    changePage: _vm.changePage,
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }